<template>
  <div>
    <div class="font-600 mb-8">application profile specific authorization data blocks</div>
    <div class="form-row form-narrow relative">
      <loader :loading="loading" :backdrop="true"/>
      <div class="w-1/3">
        <div class="form-col">
          <label>application</label>
          <Dropdown
            :options="applications"
            :value="selectedApp"
            @input="setApp"
            show-all
            no-clear
          />
        </div>
      </div>
      <div class="form-col">
        <label class="label">value</label>
        <editable-field
          ref="field"
          :initialValues="{ value: appValue }"
          editClass="mb-1"
          formClass="flex-1"
          class="app-adb-value"
          :on-submit="update"
          :close-on-submit="false"
        >
          <template v-slot:field="{ editMode, formProps }">
            <div class="flex">
              <div class="flex-1">
                <TextField
                  name="value"
                  :disabled="!editMode"
                  textClass="text-sm"
                  placeholder="free-format string"
                  :clearable="editMode && Boolean(formProps.values.value)"
                />
              </div>
              <clipboard-button
                class="focus:outline-none px-2"
                :class="editMode ? 'ml-0 mr-2' : 'ml-2'"
                :value="formProps.values.value"
              ></clipboard-button>
            </div>
          </template>
        </editable-field>
      </div>
    </div>
  </div>
</template>

<script>
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import TextField from "@/components/form/TextField";
  import Dropdown from '@/components/ui/Dropdown';
  import Loader from '@/components/ui/Loader';
  import EditableField from "@/components/ui/inlineediting/EditableField";
  import ClipboardButton from "@/components/auth/list/ClipboardButton";

  export default {
    name: "AppProfileData",
    components: {
      Dropdown,
      Loader,
      ClipboardButton,
      EditableField,
      TextField,
    },
    mixins: [ConfirmationMixin, NotifyMixin],
    data() {
      return {
        selectedApp: null,
        appValue: null,
        applications: [],
        loading: false,
      };
    },
    computed: {
      profileId() {
        return this.$route.params.profileId;
      },
    },
    watch: {
      selectedApp: {
        handler: function (value) {
          if (value) {
            this.fetchAppValue();
          }
        },
      },
    },
    methods: {
      update({ value }) {
        this.loading = true;
        this.$raaDataProvider.create('appProfileDataBlocks', {
          profileId: this.profileId,
          appCode: this.selectedApp,
          data: {
            value: value ?? '',
          },
        })
          .then(() => {
            this.$refs.field.editMode = false;
            this.appValue = value;
            this.notifySuccess('Data block has been updated');
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.loading = false;
          });
      },
      setApp(value) {
        if (value) {
          this.selectedApp = value;
        }
      },
      fetchApplications() {
        this.$raaDataProvider.getList('applications', {size: 999})
          .then(({ content }) => {
            this.applications = content.filter(item => !item.deletedAt).map(({code, name}) => ({
              key: code,
              value: name.toLocaleLowerCase(),
            }));
            this.setApp(this.applications[0]?.key);
          })
          .catch((err) => this.notifyError(err.message));
      },
      fetchAppValue() {
        this.loading = true;
        this.$raaDataProvider.get('appProfileDataBlocks', {
          profileId: this.profileId,
          appCode: this.selectedApp,
        })
          .then((result) => {
            this.appValue = result[0]?.value;
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.loading = false;
          });
      },
    },
    mounted() {
      this.fetchApplications();
    }
  }
</script>

<style scoped>
  .app-adb-value :deep(.editable-field-button) {
    opacity: 1;
    align-self: auto;
  }

  .app-adb-value :deep(.clearable-field-button) {
    bottom: 0.375rem;
    top: initial;
  }
</style>
