<template>
  <div>
    <div class="font-600 mb-4">shared relationship type specific authorization data blocks</div>
    <List
      v-if="type"
      title="shared relationship type data blocks"
      resource="sharedDataBlocks"
      :fields="fields"
      :request-params="{type: type}"
      data-provider="$raaDataProvider"
      infinity-scroll
    >
    </List>
  </div>
</template>

<script>
  import List from "@/components/auth/list/List";
  import DataRecordsField from "@/components/auth/list/fields/DataRecordsField";
  import DataKeyField from "@/components/auth/list/fields/DataKeyField";

  export default {
    name: "SharedRelationshipTypeData",
    components: {
      List,
    },
    props: {
      type: {
        type: String,
        required: true,
        default: '',
      },
    },
    data() {
      return {
        fields: [
          {
            name: DataKeyField,
            title: 'name',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '35%'
          },
          {
            name: 'version',
            title: 'version',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '10%'
          },
          {
            name: DataRecordsField,
            title: 'data records',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '55%'
          },
        ],
      };
    },
  }
</script>

<style scoped>

</style>
