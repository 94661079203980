<template>
  <Details
    title="profile"
    resource="profiles"
    :base-path="basePath"
    data-provider="$raaDataProvider"
    route-id-param="profileId"
  >
    <template v-slot:default="detailsProps">
      <div>
        <div v-if="detailsProps.record.deletedAt" class="flex justify-end text-sm mb-4">
          {{detailsProps.getDeletedLabel(detailsProps.record.deletedAt)}}
        </div>
      </div>
      <DetailsBar class="flex relative mb-10">
        <div class="w-1/3">
          <label class="label">person</label>
          <div class="mb-4">
            <div>{{ detailsProps.record.fullName }}</div>
            <div class="text-sm">{{ detailsProps.record.email }}</div>
          </div>
          <label class="label">relationship type</label>
          <div class="mb-4">{{ detailsProps.record.relationshipType }}</div>
        </div>
        <div class="w-2/3 mx-8">
          <label class="label">community</label>
          <div class="mb-4">{{ detailsProps.record.communityName }}</div>
          <template v-if="detailsProps.record.leaseId">
            <label class="label">location</label>
            <div class="mb-4">{{ detailsProps.record.locationName }}</div>
            <label class="label">lease information</label>
            <div class="mb-4">
              <div>{{getLeaseDates(detailsProps.record)}}</div>
              <div class="text-sm">{{ detailsProps.record.leaseType }}</div>
            </div>
          </template>
        </div>
        <div v-if="!detailsProps.record.deletedAt" class="absolute top-3 right-3">
          <button @click="editProfile">
            <icon name="pencil" class="edit-icon"/>
          </button>
        </div>
      </DetailsBar>

      <div class="flex items-center mb-6">
        <span class="font-600 mr-2">authorization data block management</span>
        <Tooltip icon="info" text="Profile structure includes profile and relationship type specific authorization data in shared and application areas.
        Profile specific data is editable while relationship type specific data is readonly."/>
      </div>

      <div class="flex flex-1">
        <div class="side-panel">
          <DetailsSidePanel>
            <template v-slot:heading>profile structure data</template>
            <template v-slot:text>
              <p class="text-sm font-inter text-blue-800 mt-4 font-500">
                Select specific authorization data of the certain area
              </p>
              <div class="flex-column space-y-2 mt-4">
                <div
                  v-for="(tab, index) in tabs"
                  class="nav-tab"
                  :class="{active: activeTab === tab.key }"
                  :key="index"
                  @click="activeTab = tab.key"
                >
                  {{ tab.name }}
                </div>
              </div>
            </template>
          </DetailsSidePanel>
        </div>
        <div class="w-2/3">
          <component
              :is="activeTabComponent"
              :component="activeTabComponent"
              :type="detailsProps.record.relationshipType"
              class="tab pt-4"
            ></component>
        </div>
      </div>
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import ActionsMixin from "@/mixins/ActionsMixin";
  import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
  import Icon from "@/components/ui/Icon";
  import Tooltip from "@/components/ui/Tooltip";
  import Details from '@/components/auth/details/Details';
  import DetailsBar from '@/components/auth/details/DetailsBar';
  import DetailsSidePanel from '@/components/auth/details/DetailsSidePanel';
  import SharedProfileData from "@/views/auth/raa-devtools/profiles/authorization-data/SharedProfileData";
  import SharedRelationshipTypeData from "@/views/auth/raa-devtools/profiles/authorization-data/SharedRelationshipTypeData";
  import AppRelationshipTypeData from "@/views/auth/raa-devtools/profiles/authorization-data/AppRelationshipTypeData";
  import AppProfileData from "@/views/auth/raa-devtools/profiles/authorization-data/AppProfileData";

  const componentsMap = {
    shared: SharedProfileData,
    app: AppProfileData,
    sharedRelationship: SharedRelationshipTypeData,
    appRelationship: AppRelationshipTypeData,
  };

export default {
  name: 'ProfileDetails',
  mixins: [ModalNavigation, NotifyMixin, ActionsMixin, CommunityTimezoneMixin],
  components: {
    Icon,
    Tooltip,
    Details,
    DetailsBar,
    DetailsSidePanel,
    SharedProfileData,
    SharedRelationshipTypeData,
    AppRelationshipTypeData,
    AppProfileData,
  },
  data() {
    return {
      activeTab: "shared",
      tabs: [
          { key: "shared", name: "shared profile" },
          { key: "app", name: "app profile" },
          { key: "sharedRelationship", name: "shared relationship type" },
          { key: "appRelationship", name: "app relationship type" },
      ],
    };
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
    profileId() {
      return this.$route.params.profileId;
    },
    activeTabComponent: function() {
      return componentsMap[this.activeTab];
    }
  },
  methods: {
    getLeaseDates({ leaseStartDate, leaseEndDate, timezoneId } = {}) {
      const startTime = this.parseDateTime(leaseStartDate, false, timezoneId).format('LL');
      const endTime = leaseEndDate ? this.parseDateTime(leaseEndDate, false, timezoneId).format('LL') : 'present';

      return `${startTime} - ${endTime}`;
    },
    editProfile() {
      this.$router.push({
        name: this.$route.name.replace('details', 'edit'),
        params: this.$route.params,
      });
    },
  },
};
</script>

<style scoped>
  .nav-tab {
    @apply flex flex-grow border border-gray-300 p-2 cursor-pointer text-black;
  }

  .nav-tab.active {
    color: var(--highlightColor500);
    border-color: var(--highlightColor500);
  }
</style>
