<template>
  <div>
    <div class="flex justify-between items-center mb-4">
      <div class="font-600">shared profile specific authorization data blocks</div>
      <button type="button" class="btn-primary" @click="addDataBlock">
        add data block
      </button>
    </div>
    <List
      ref="list"
      title="shared profile data blocks"
      resource="profileDataBlocks"
      :fields="fields"
      :request-params="{profileId: profileId}"
      data-provider="$raaDataProvider"
      @row-clicked="handleRowClick"
      infinity-scroll
    >
      <template v-slot:inline-actions="actionsScope">
        <button
          class="btn-action-warning focus:outline-none"
          v-if="!actionsScope.record.deletedAt"
          @click.stop="deleteDataBlock(actionsScope.record)"
        >
          <icon class="w-5 h-5 mr-1" name="trash"/>
        </button>
      </template>
    </List>
  </div>
</template>

<script>
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import NotifyMixin from "@/mixins/NotifyMixin";
  import List from "@/components/auth/list/List";
  import DataRecordsField from "@/components/auth/list/fields/DataRecordsField";
  import DataKeyField from "@/components/auth/list/fields/DataKeyField";
  import Icon from "@/components/ui/Icon";

  export default {
    name: "SharedProfileData",
    components: {
      List,
      Icon,
    },
    mixins: [ConfirmationMixin, NotifyMixin],
    data() {
      return {
        fields: [
          {
            name: DataKeyField,
            title: 'name',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '30%'
          },
          {
            name: 'version',
            title: 'version',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '10%'
          },
          {
            name: DataRecordsField,
            title: 'data records',
            titleClass: 'font-medium text-xs',
            dataClass:'text-sm',
            width: '50%'
          },
          {
            name: 'inline-actions',
            title: '',
            width: '10%',
          }
        ],
      };
    },
    computed: {
      profileId() {
        return this.$route.params.profileId;
      },
    },
    methods: {
      addDataBlock() {
        this.$router.replace({
          name: 'raaTools.profiles.dataBlocks.create',
          params: { profileId: this.profileId }
        });
      },
      handleRowClick({ data: { name, version } }) {
        this.$router.replace({
          name: 'raaTools.profiles.dataBlocks.edit',
          params: { profileId: this.profileId, name, version }
        });
      },
      deleteDataBlock({ name, version }) {
        this.requestConfirmation({
          confirmationMessage: 'Are you sure you want to delete this data block?',
          confirmBtnText: 'yes',
          cancelBtnText: 'no',
          confirmationType: 'warning'
        })
          .then(confirmed => confirmed
            ? this.$raaDataProvider.delete('profileDataBlockVersioned', { name, version, profileId: this.profileId })
              .then(() => {
                this.$refs.list.doSearch();
                this.notifySuccess('Profile data block is deleted');
              })
              .catch(error => this.notifyError(error.message))
            : null
          );
      },
    },
  }
</script>

<style scoped>

</style>
