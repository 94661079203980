<template>
  <div>
    <div class="font-600 mb-4">application relationship type specific authorization data blocks</div>
    <div class="form-row w-1/3 py-2">
      <div class="form-col">
        <label>application</label>
        <Dropdown
          :options="applications"
          :value="selectedApp"
          @input="setApp"
          show-all
          no-clear
        />
      </div>
    </div>
    <List
      v-if="!!appCode"
      :key="appCode"
      title="app relationship type data blocks"
      resource="appDataBlocks"
      :fields="fields"
      :request-params="{appCode: appCode, type: type}"
      data-provider="$raaDataProvider"
      infinity-scroll
    >
    </List>
  </div>
</template>

<script>
  import NotifyMixin from "@/mixins/NotifyMixin";
  import Dropdown from '@/components/ui/Dropdown';
  import List from "@/components/auth/list/List";

  export default {
    name: "AppRelationshipTypeData",
    components: {
      Dropdown,
      List,
    },
    mixins: [NotifyMixin],
    props: {
      type: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        selectedApp: null,
        applications: [],
        records: [],
        fields: [
          {
            name: 'version',
            title: 'version',
            titleClass: 'font-medium text-xs',
            dataClass: 'text-sm',
            width: '10%',
          },
          {
            name: 'value',
            title: 'value',
            titleClass: 'font-medium text-xs',
            dataClass: 'text-sm',
            width: '90%',
          },
        ],
      };
    },
    computed: {
      appCode() {
        return this.selectedApp;
      }
    },
    methods: {
      setApp(value) {
        if (value) {
          this.selectedApp = value;
        }
      },
      fetchApplications() {
        this.$raaDataProvider.getList('applications', {size: 999})
          .then(({ content }) => {
            this.applications = content.map(({code, name}) => ({
              key: code,
              value: name.toLocaleLowerCase(),
            }));
            this.setApp(this.applications[0]?.key);
          })
          .catch((err) => this.notifyError(err.message));
      },
    },
    mounted() {
      this.fetchApplications();
    }
  }
</script>

<style scoped>

</style>
